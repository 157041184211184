define("discourse/plugins/discourse-question-answer/discourse/initializers/qa-edits", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "virtual-dom", "discourse/widgets/post", "discourse/helpers/node", "discourse/models/composer", "discourse/plugins/discourse-question-answer/discourse/lib/qa-utilities", "discourse/widgets/actions-summary", "discourse/lib/posts-with-placeholders", "@ember/runloop"], function (_exports, _pluginApi, _decorators, _virtualDom, _post, _node, _composer, _qaUtilities, _actionsSummary, _postsWithPlaceholders, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function initPlugin(api) {
    var _dec, _dec2, _dec3, _obj, _dec4, _obj2, _dec5, _dec6, _obj3, _dec7, _dec8, _obj4;
    const currentUser = api.getCurrentUser();
    api.reopenWidget("post-menu", {
      menuItems() {
        const attrs = this.attrs;
        let result = this.siteSettings.post_menu.split("|");
        if (attrs.qa_enabled) {
          const post = this.findAncestorModel();
          const category = post.topic.category;
          let type = attrs.firstPost ? "questions" : attrs.reply_to_post_number ? "comments" : "answers";
          let disableLikes = this.siteSettings.qa_disable_like_on_answers || category && category[`qa_disable_like_on_${type}`];
          if (disableLikes) {
            result = result.filter(b => b !== "like");
          }
          result = result.filter(b => b !== "reply");
        }
        return result;
      }
    });
    api.decorateWidget("post:before", function (helper) {
      const result = [];
      const model = helper.getModel();
      const firstAnswer = helper.widget.model.get("topic.first_answer_id");
      if (helper.attrs.id === firstAnswer && model.qa_enabled) {
        const answerCount = helper.widget.model.get("topic.answer_count");
        const answers = I18n.t("qa.answer_count", {
          answerCount
        });
        result.push(helper.h("div.qa-answer-count.small-action", answers));
      }
      if (model && model.get("post_number") !== 1 && !model.get("reply_to_post_number") && model.get("qa_enabled")) {
        const qaPost = helper.attach("qa-post", {
          count: model.get("qa_vote_count"),
          post: model
        });
        result.push(qaPost);
      }
      return result;
    });
    api.decorateWidget("post:after", function (helper) {
      const model = helper.getModel();
      if (model.attachCommentToggle && model.hiddenComments > 0) {
        let type = Number(helper.widget.siteSettings.qa_comments_default) > 0 ? "more" : "all";
        return helper.attach("link", {
          action: "showComments",
          actionParam: model.answerId,
          rawLabel: I18n.t(`topic.comment.show_comments.${type}`, {
            count: model.hiddenComments
          }),
          className: "show-comments"
        });
      }
    });
    api.reopenWidget("post-stream", {
      buildKey: () => "post-stream",
      defaultState(attrs, state) {
        let defaultState = this._super(attrs, state);
        defaultState["showComments"] = [];
        return defaultState;
      },
      showComments(answerId) {
        let showComments = this.state.showComments;
        if (showComments.indexOf(answerId) === -1) {
          showComments.push(answerId);
          this.state.showComments = showComments;
          this.appEvents.trigger("post-stream:refresh", {
            force: true
          });
        }
      },
      html(attrs, state) {
        let posts = attrs.posts || [];
        let postArray = this.capabilities.isAndroid ? posts : posts.toArray();
        if (postArray[0] && postArray[0].qa_enabled) {
          let answerId = null;
          let showComments = state.showComments;
          let defaultComments = Number(this.siteSettings.qa_comments_default);
          let commentCount = 0;
          let lastVisible = null;
          postArray.forEach((p, i) => {
            if (!p.topic) {
              return;
            }
            p["oneToMany"] = p.topic.category.qa_one_to_many;
            if (p.reply_to_post_number) {
              commentCount++;
              p["comment"] = true;
              p["showComment"] = showComments.indexOf(answerId) > -1 || commentCount <= defaultComments;
              p["answerId"] = answerId;
              p["attachCommentToggle"] = false;
              if (p["showComment"]) lastVisible = i;
              if ((!postArray[i + 1] || !postArray[i + 1].reply_to_post_number) && !p["showComment"]) {
                postArray[lastVisible]["answerId"] = answerId;
                postArray[lastVisible]["attachCommentToggle"] = true;
                postArray[lastVisible]["hiddenComments"] = commentCount - defaultComments;
              }
            } else {
              p["attachCommentToggle"] = !p["oneToMany"];
              p["topicUserId"] = p.topic.user_id;
              answerId = p.id;
              commentCount = 0;
              lastVisible = i;
            }
          });
          if (this.capabilities.isAndroid) {
            attrs.posts = postArray;
          } else {
            attrs.posts = _postsWithPlaceholders.default.create({
              posts: postArray,
              store: this.store
            });
          }
        }
        return this._super(attrs, state);
      }
    });
    api.includePostAttributes("qa_enabled", "reply_to_post_number", "comment", "showComment", "answerId", "lastComment", "last_answerer", "last_answered_at", "answer_count", "last_answer_post_number", "last_answerer", "topicUserId", "oneToMany");
    api.addPostClassesCallback(attrs => {
      if (attrs.qa_enabled && !attrs.firstPost) {
        if (attrs.comment) {
          let classes = ["comment"];
          if (attrs.showComment) {
            classes.push("show");
          }
          return classes;
        } else {
          return ["answer"];
        }
      }
    });
    api.addPostMenuButton("answer", attrs => {
      if (attrs.canCreatePost && attrs.qa_enabled && attrs.firstPost && (!attrs.oneToMany || attrs.topicUserId === currentUser.id)) {
        let postType = attrs.oneToMany ? "one_to_many" : "answer";
        let args = {
          action: "replyToPost",
          title: `topic.${postType}.help`,
          icon: "reply",
          className: "answer create fade-out"
        };
        if (!attrs.mobileView) {
          args.label = `topic.${postType}.title`;
        }
        return args;
      }
    });
    api.addPostMenuButton("comment", attrs => {
      if (attrs.canCreatePost && attrs.qa_enabled &&
      // !attrs.firstPost &&
      !attrs.reply_to_post_number) {
        let args = {
          action: "openCommentCompose",
          title: "topic.comment.help",
          icon: "comment",
          className: "comment create fade-out"
        };
        if (!attrs.mobileView) {
          args.label = "topic.comment.title";
        }
        return args;
      }
    });
    api.modifyClass("component:composer-actions", (_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.default)("pluginPostSnapshot"), _dec3 = (0, _decorators.default)("options", "canWhisper", "action", "commenting"), (_obj = {
      setupPost() {
        const composerPost = this.get("composerModel.post");
        if (composerPost) {
          this.set("pluginPostSnapshot", composerPost);
        }
      },
      commenting(post) {
        return post && post.get("topic.qa_enabled") &&
        // !post.get("firstPost") &&
        !post.reply_to_post_number;
      },
      computeHeaderContent() {
        let content = this._super();
        if (this.get("commenting") && this.get("action") === _composer.REPLY && this.get("options.userAvatar")) {
          content.icon = "comment";
        }
        return content;
      },
      content(options, canWhisper, action, commenting) {
        let items = this._super(...arguments);
        if (commenting) {
          items.forEach(item => {
            if (item.id === "reply_to_topic") {
              item.name = I18n.t("composer.composer_actions.reply_to_question.label");
              item.description = I18n.t("composer.composer_actions.reply_to_question.desc");
            }
            if (item.id === "reply_to_post") {
              item.icon = "comment";
              item.name = I18n.t("composer.composer_actions.comment_on_answer.label", {
                postUsername: this.get("pluginPostSnapshot.username")
              });
              item.description = I18n.t("composer.composer_actions.comment_on_answer.desc");
            }
          });
        }
        return items;
      }
    }, (_applyDecoratedDescriptor(_obj, "setupPost", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupPost"), _obj), _applyDecoratedDescriptor(_obj, "commenting", [_dec2], Object.getOwnPropertyDescriptor(_obj, "commenting"), _obj), _applyDecoratedDescriptor(_obj, "content", [_dec3], Object.getOwnPropertyDescriptor(_obj, "content"), _obj)), _obj)));
    api.reopenWidget("post-body", {
      buildKey: attrs => `post-body-${attrs.id}`,
      defaultState(attrs) {
        let state = this._super();
        if (attrs.qa_enabled) {
          state = $.extend({}, state, {
            voters: []
          });
        }
        return state;
      },
      html(attrs, state) {
        let contents = this._super(attrs, state);
        const model = this.findAncestorModel();
        let action = model.actionByName["vote"];
        if (action && attrs.qa_enabled) {
          let voteLinks = [];
          attrs.actionsSummary = attrs.actionsSummary.filter(as => as.action !== "vote");
          if (action.acted && action.can_undo) {
            voteLinks.push(this.attach("link", {
              action: "undoUserVote",
              rawLabel: I18n.t("post.actions.undo.vote")
            }));
          }
          if (action.count > 0) {
            voteLinks.push(this.attach("link", {
              action: "toggleWhoVoted",
              rawLabel: `${action.count} ${I18n.t("post.actions.people.vote")}`
            }));
          }
          if (voteLinks.length) {
            let voteContents = [(0, _virtualDom.h)("div.vote-links", voteLinks)];
            if (state.voters.length) {
              voteContents.push(this.attach("small-user-list", {
                users: state.voters,
                listClassName: "voters"
              }));
            }
            let actionSummaryIndex = contents.map(w => w && w.name).indexOf("actions-summary");
            let insertAt = actionSummaryIndex + 1;
            contents.splice(insertAt - 1, 0, (0, _virtualDom.h)("div.vote-container", voteContents));
          }
        }
        return contents;
      },
      undoUserVote() {
        const post = this.findAncestorModel();
        const user = this.currentUser;
        const vote = {
          user_id: user.id,
          post_id: post.id,
          direction: "up"
        };
        (0, _qaUtilities.undoVote)({
          vote
        }).then(result => {
          if (result.success) {
            post.set("topic.voted", false);
          }
        });
      },
      toggleWhoVoted() {
        const state = this.state;
        if (state.voters.length) {
          state.voters = [];
        } else {
          return this.getWhoVoted();
        }
      },
      getWhoVoted() {
        const {
          attrs,
          state
        } = this;
        const post = {
          post_id: attrs.id
        };
        (0, _qaUtilities.whoVoted)(post).then(result => {
          if (result.voters) {
            state.voters = result.voters.map(_actionsSummary.smallUserAtts);
            this.scheduleRerender();
          }
        });
      }
    });
    api.modifyClass("model:topic", (_dec4 = (0, _decorators.default)("qa_enabled"), (_obj2 = {
      showQaTip(qaEnabled) {
        return qaEnabled && this.siteSettings.qa_show_topic_tip;
      }
    }, (_applyDecoratedDescriptor(_obj2, "showQaTip", [_dec4], Object.getOwnPropertyDescriptor(_obj2, "showQaTip"), _obj2)), _obj2)));
    api.modifyClass("component:topic-footer-buttons", (_dec5 = (0, _decorators.on)("didInsertElement"), _dec6 = (0, _decorators.observes)("topic.qa_enabled"), (_obj3 = {
      hideFooterReply() {
        const qaEnabled = this.get("topic.qa_enabled");
        Ember.run.scheduleOnce("afterRender", () => {
          $(".topic-footer-main-buttons > button.create:not(.answer)", this.element).toggle(!qaEnabled);
        });
      }
    }, (_applyDecoratedDescriptor(_obj3, "hideFooterReply", [_dec5, _dec6], Object.getOwnPropertyDescriptor(_obj3, "hideFooterReply"), _obj3)), _obj3)));
    api.modifyClass("model:post-stream", {
      prependPost(post) {
        const stored = this.storePost(post);
        if (stored) {
          const posts = this.get("posts");
          let insertPost = () => posts.unshiftObject(stored);
          const qaEnabled = this.get("topic.qa_enabled");
          if (qaEnabled && post.post_number === 2 && posts[0].post_number === 1) {
            insertPost = () => posts.insertAt(1, stored);
          }
          insertPost();
        }
        return post;
      },
      appendPost(post) {
        const stored = this.storePost(post);
        if (stored) {
          const posts = this.get("posts");
          if (!posts.includes(stored)) {
            const replyingTo = post.get("reply_to_post_number");
            const qaEnabled = this.get("topic.qa_enabled");
            let insertPost = () => posts.pushObject(stored);
            if (qaEnabled && replyingTo) {
              let passed = false;
              posts.some((p, i) => {
                if (passed && !p.reply_to_post_number) {
                  insertPost = () => posts.insertAt(i, stored);
                  return true;
                }
                if (p.post_number === replyingTo && i < posts.length - 1) {
                  passed = true;
                }
              });
            }
            if (!this.get("loadingBelow")) {
              this.get("postsWithPlaceholders").appendPost(insertPost);
            } else {
              insertPost();
            }
          }
          if (stored.get("id") !== -1) {
            this.set("lastAppended", stored);
          }
        }
        return post;
      }
    });
    api.modifyClass("component:topic-progress", (_dec7 = (0, _decorators.default)("postStream.loaded", "topic.currentPost", "postStream.filteredPostsCount", "topic.qa_enabled"), _dec8 = (0, _decorators.default)("progressPosition", "topic.last_read_post_id", "topic.qa_enabled"), (_obj4 = {
      hideProgress(loaded, currentPost, filteredPostsCount, qaEnabled) {
        return qaEnabled || !loaded || !currentPost || !this.site.mobileView && filteredPostsCount < 2;
      },
      showBackButton(position, lastReadId, qaEnabled) {
        if (!lastReadId || qaEnabled) {
          return;
        }
        const stream = this.get("postStream.stream");
        const readPos = stream.indexOf(lastReadId) || 0;
        return readPos < stream.length - 1 && readPos > position;
      }
    }, (_applyDecoratedDescriptor(_obj4, "hideProgress", [_dec7], Object.getOwnPropertyDescriptor(_obj4, "hideProgress"), _obj4), _applyDecoratedDescriptor(_obj4, "showBackButton", [_dec8], Object.getOwnPropertyDescriptor(_obj4, "showBackButton"), _obj4)), _obj4)));
    api.modifyClass("component:topic-navigation", {
      _performCheckSize() {
        if (!this.element || this.isDestroying || this.isDestroyed) return;
        if (this.get("topic.qa_enabled")) {
          const info = this.get("info");
          info.setProperties({
            renderTimeline: false,
            renderAdminMenuButton: true
          });
        } else {
          this._super(...arguments);
        }
      }
    });
    api.reopenWidget("post", {
      html(attrs) {
        if (attrs.cloaked) {
          return "";
        }
        if (attrs.qa_enabled && !attrs.firstPost) {
          attrs.replyToUsername = null;
          if (attrs.reply_to_post_number) {
            attrs.canCreatePost = false;
            api.changeWidgetSetting("post-avatar", "size", "small");
          } else {
            attrs.replyCount = null;
            api.changeWidgetSetting("post-avatar", "size", "large");
          }
        }
        return this.attach("post-article", attrs);
      },
      openCommentCompose() {
        this.sendWidgetAction("showComments", this.attrs.id);
        this.sendWidgetAction("replyToPost", this.model).then(() => {
          (0, _runloop.next)(this, () => {
            const composer = api.container.lookup("controller:composer");
            if (!composer.model.post) {
              composer.model.set("post", this.model);
            }
          });
        });
      }
    });
    function renderParticipants(userFilters, participants) {
      if (!participants) {
        return;
      }
      userFilters = userFilters || [];
      return participants.map(p => {
        return this.attach("topic-participant", p, {
          state: {
            toggled: userFilters.includes(p.username)
          }
        });
      });
    }
    api.reopenWidget("topic-map-summary", {
      html(attrs, state) {
        if (attrs.qa_enabled) {
          return this.qaMap(attrs, state);
        } else {
          return this._super(attrs, state);
        }
      },
      qaMap(attrs, state) {
        const contents = [];
        contents.push((0, _virtualDom.h)("li", [(0, _virtualDom.h)("h4", I18n.t("created_lowercase")), (0, _virtualDom.h)("div.topic-map-post.created-at", [(0, _post.avatarFor)("tiny", {
          username: attrs.createdByUsername,
          template: attrs.createdByAvatarTemplate,
          name: attrs.createdByName
        }), (0, _node.dateNode)(attrs.topicCreatedAt)])]));
        let lastAnswerUrl = attrs.topicUrl + "/" + attrs.last_answer_post_number;
        let postType = attrs.oneToMany ? "one_to_many" : "answer";
        contents.push((0, _virtualDom.h)("li", (0, _virtualDom.h)("a", {
          attributes: {
            href: lastAnswerUrl
          }
        }, [(0, _virtualDom.h)("h4", I18n.t(`last_${postType}_lowercase`)), (0, _virtualDom.h)("div.topic-map-post.last-answer", [(0, _post.avatarFor)("tiny", {
          username: attrs.last_answerer.username,
          template: attrs.last_answerer.avatar_template,
          name: attrs.last_answerer.name
        }), (0, _node.dateNode)(attrs.last_answered_at)])])));
        contents.push((0, _virtualDom.h)("li", [(0, _node.numberNode)(attrs.answer_count), (0, _virtualDom.h)("h4", I18n.t(`${postType}_lowercase`, {
          count: attrs.answer_count
        }))]));
        contents.push((0, _virtualDom.h)("li.secondary", [(0, _node.numberNode)(attrs.topicViews, {
          className: attrs.topicViewsHeat
        }), (0, _virtualDom.h)("h4", I18n.t("views_lowercase", {
          count: attrs.topicViews
        }))]));
        contents.push((0, _virtualDom.h)("li.secondary", [(0, _node.numberNode)(attrs.participantCount), (0, _virtualDom.h)("h4", I18n.t("users_lowercase", {
          count: attrs.participantCount
        }))]));
        if (attrs.topicLikeCount) {
          contents.push((0, _virtualDom.h)("li.secondary", [(0, _node.numberNode)(attrs.topicLikeCount), (0, _virtualDom.h)("h4", I18n.t("likes_lowercase", {
            count: attrs.topicLikeCount
          }))]));
        }
        if (attrs.topicLinkLength > 0) {
          contents.push((0, _virtualDom.h)("li.secondary", [(0, _node.numberNode)(attrs.topicLinkLength), (0, _virtualDom.h)("h4", I18n.t("links_lowercase", {
            count: attrs.topicLinkLength
          }))]));
        }
        if (state.collapsed && attrs.topicPostsCount > 2 && attrs.participants.length > 0) {
          const participants = renderParticipants.call(this, attrs.userFilters, attrs.participants.slice(0, 3));
          contents.push((0, _virtualDom.h)("li.avatars", participants));
        }
        const nav = (0, _virtualDom.h)("nav.buttons", this.attach("button", {
          title: "topic.toggle_information",
          icon: state.collapsed ? "chevron-down" : "chevron-up",
          action: "toggleMap",
          className: "btn"
        }));
        return [nav, (0, _virtualDom.h)("ul.clearfix", contents)];
      }
    });
    api.reopenWidget("post-admin-menu", {
      html() {
        const result = this._super(...arguments);
        if (this.attrs.qa_enabled && this.attrs.comment) {
          const button = {
            label: "qa.set_as_answer",
            action: "setAsAnswer",
            className: "popup-menu-button",
            secondaryAction: "closeAdminMenu"
          };
          result.children.push(this.attach("post-admin-menu-button", button));
        }
        return result;
      },
      setAsAnswer() {
        const post = this.findAncestorModel();
        (0, _qaUtilities.setAsAnswer)(post).then(result => {
          location.reload();
        });
      }
    });
  }
  var _default = _exports.default = {
    name: "qa-edits",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.qa_enabled) return;
      (0, _pluginApi.withPluginApi)("0.8.12", initPlugin);
    }
  };
});