define("discourse/plugins/discourse-question-answer/discourse/lib/qa-utilities", ["exports", "discourse/lib/ajax-error", "discourse/lib/ajax"], function (_exports, _ajaxError, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.castVote = void 0;
  _exports.setAsAnswer = setAsAnswer;
  _exports.whoVoted = _exports.voteActionId = _exports.undoVote = void 0;
  const voteActionId = _exports.voteActionId = 100;
  const vote = function (type, data) {
    return (0, _ajax.ajax)("/qa/vote", {
      type,
      data
    }).catch(_ajaxError.popupAjaxError);
  };
  const undoVote = function (data) {
    return vote("DELETE", data);
  };
  _exports.undoVote = undoVote;
  const castVote = function (data) {
    return vote("POST", data);
  };
  _exports.castVote = castVote;
  const whoVoted = function (data) {
    return (0, _ajax.ajax)("/qa/voters", {
      type: "GET",
      data
    }).catch(_ajaxError.popupAjaxError);
  };
  _exports.whoVoted = whoVoted;
  function setAsAnswer(post) {
    return (0, _ajax.ajax)("/qa/set_as_answer", {
      type: "POST",
      data: {
        post_id: post.id
      }
    }).catch(_ajaxError.popupAjaxError);
  }
});