define("discourse/plugins/discourse-question-answer/discourse/widgets/qa-post", ["exports", "discourse/widgets/widget", "discourse/plugins/discourse-question-answer/discourse/lib/qa-utilities", "virtual-dom"], function (_exports, _widget, _qaUtilities, _virtualDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("qa-post", {
    tagName: "div.qa-post",
    sendShowLogin() {
      const appRoute = this.register.lookup("route:application");
      appRoute.send("showLogin");
    },
    html(attrs) {
      const contents = [this.attach("qa-button", {
        direction: "up"
      }), (0, _virtualDom.h)("div.count", `${attrs.count}`)];
      return contents;
    },
    vote(direction) {
      const user = this.currentUser;
      if (!user) {
        return this.sendShowLogin();
      }
      const post = this.attrs.post;
      let vote = {
        user_id: user.id,
        post_id: post.id,
        direction
      };
      (0, _qaUtilities.castVote)({
        vote
      }).then(result => {
        if (result.success) {
          post.set("topic.qa_voted", true);
          if (result.qa_can_vote) {
            post.set("topic.qa_can_vote", result.qa_can_vote);
          }
          if (result.qa_votes) {
            post.set("topic.qa_votes", result.qa_votes);
          }
        }
      });
    }
  });
});