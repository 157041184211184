define("discourse/plugins/discourse-question-answer/discourse/connectors/after-topic-footer-main-buttons/answer-button-wrapper", ["exports", "discourse-common/lib/get-owner"], function (_exports, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(attrs, component) {
      const currentUser = component.get("currentUser");
      const topic = attrs.topic;
      const oneToMany = topic.category && topic.category.qa_one_to_many;
      const qaEnabled = topic.qa_enabled;
      const canCreatePost = topic.get("details.can_create_post");
      let showCreateAnswer = qaEnabled && canCreatePost && (!oneToMany || topic.user_id == currentUser.id);
      let label;
      let title;
      if (showCreateAnswer) {
        let topicType = oneToMany ? "one_to_many" : "answer";
        label = `topic.${topicType}.title`;
        title = `topic.${topicType}.help`;
      }
      component.setProperties({
        showCreateAnswer,
        label,
        title
      });
    },
    actions: {
      answerQuestion() {
        const controller = (0, _getOwner.getOwner)(this).lookup("controller:topic");
        controller.send("replyToPost");
      }
    }
  };
});