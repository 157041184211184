define("discourse/plugins/discourse-question-answer/discourse/widgets/qa-button", ["exports", "discourse/widgets/widget", "discourse-common/lib/icon-library"], function (_exports, _widget, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("qa-button", {
    tagName: "button.btn.qa-button",
    html(attrs) {
      return (0, _iconLibrary.iconNode)(`angle-${attrs.direction}`);
    },
    click() {
      this.sendWidgetAction("vote", this.attrs.direction);
    }
  });
});