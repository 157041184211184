define("discourse/plugins/discourse-question-answer/discourse/components/user-vote-count", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ["user-vote-count"]
  });
});